import React, { useEffect, useState } from "react"
import axios from "axios"
import MODURL from "../MODURL" // Assuming MODURL is set correctly
import IMAGE_BASE_URL from "../IMAGE_BASE_URL" // Assuming IMAGE_BASE_URL is set correctly
import { Link } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import LogoutIcon from "@mui/icons-material/Logout"
import { useNavigate } from "react-router-dom"

const CurrentOrders = () => {
  const [orders, setOrders] = useState([]) // State to store confirmed orders
  const [loading, setLoading] = useState(true) // Loading state to show loading spinner
  const [cartsidebarOpen, setCartSidebarOpen] = useState(false) // State to control sidebar open/close
  const [open, setOpen] = useState(false) // State for logout dialog (optional)
  const navigate = useNavigate() // Initialize the navigate hook
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [selectedImage, setSelectedImage] = useState(null)

  const handleLogout = () => {
    localStorage.clear()
    navigate("/") // Navigate to home or login page
  }

  useEffect(() => {
    const fetchConfirmedOrders = async () => {
      const token = localStorage.getItem("token")

      try {
        const response = await axios.get(`${MODURL}/currentorders`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        setOrders(response.data) // Update state with fetched data
      } catch (error) {
        console.error("Error fetching confirmed orders:", error)
      } finally {
        setLoading(false) // Set loading to false after fetching
      }
    }

    fetchConfirmedOrders()
  }, [])

  if (loading) {
    return <div>Loading...</div> // Display loading message or spinner
  }
  const handleImageClick = (image) => {
    setSelectedImage(image)
  }
  const handleClose = () => {
    setSelectedImage(null)
  }

  return (
    <Box>
      {/* Navbar */}
      <AppBar position="static" sx={{ bgcolor: "#1E88E5", padding: 1 }}>
        <Toolbar>
          <IconButton color="inherit" onClick={() => setCartSidebarOpen(true)}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, cursor: "pointer" }}
            onClick={() => navigate("/cart")}
          >
            View Your Cart
          </Typography>
          <IconButton color="inherit" onClick={() => navigate("/cart")}>
            <ShoppingCartIcon />
          </IconButton>

          {/* Logout Button */}
          <IconButton color="inherit" onClick={() => setOpen(true)}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Logout Confirmation Modal */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="error">
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sidebar Drawer */}
      <Drawer
        anchor="left"
        open={cartsidebarOpen}
        onClose={() => setCartSidebarOpen(false)}
      >
        <Box
          sx={{
            width: 250,
            padding: 2,
          }}
        >
          <List>
            <ListItem
              button
              component={Link}
              to="/order"
              sx={{ cursor: "pointer" }}
            >
              <ListItemText primary="Add Items" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/cart"
              sx={{ cursor: "pointer" }}
            >
              <ListItemText primary="View Cart" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => setOpen(true)}
              sx={{ cursor: "pointer" }}
            >
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Page Content */}
      <Box sx={{ padding: 2, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Your Current Orders
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          size="large"
          onClick={() => navigate("/order")}
        >
          Back to Order Page/ Browse More Products To Add
        </Button>
        {orders.length === 0 ? (
          <Typography variant="h6" color="textSecondary">
            You have no Pending orders.
          </Typography>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "repeat(2, 1fr)"
                : "repeat(auto-fit, minmax(300px, 1fr))",
              gap: 2,
            }}
          >
            {orders.map((order) => (
              <Card key={order.id} sx={{ maxWidth: 345, mt: 2 }}>
                <CardMedia
                  component="img"
                  image={`${IMAGE_BASE_URL}${order.image_link}`}
                  alt={order.item_name}
                  onClick={() =>
                    handleImageClick(`${IMAGE_BASE_URL}${order.image_link}`)
                  }
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: { xs: "0.8rem", sm: "1rem" },
                    }}
                  >
                    {order.item_name}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="black"
                    fontSize="large"
                    sx={{ fontWeight: "bold" }}
                  >
                    Quantity Pending: {order.quantity}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
      </Box>
      <Dialog
        open={!!selectedImage}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
            p: 0,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              backgroundColor: "rgba(255,255,255,0.5)",
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Full Size"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default CurrentOrders
