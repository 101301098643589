import React, { useState } from "react"
import axios from "axios"
import { TextField, Button, Container, Typography, Alert } from "@mui/material"
import MODURL from "../MODURL"

const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    setMessage("")
    setError("")

    try {
      const response = await axios.post(`${MODURL}/forgot-password`, { email })
      setMessage(response.data.message)
    } catch (err) {
      setError("Error sending reset link. Please try again.")
    }
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h5" textAlign="center" mb={2}>
        Forgot Password
      </Typography>
      <Typography variant="h5" textAlign="center" mb={2}>
        You Can Get a Link By Putting in Your Email Address , to reset Your
        Password
      </Typography>
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}

      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" variant="contained" fullWidth>
          Send Reset Link
        </Button>
        <Typography variant="h7" textAlign="center" mb={2}>
          Please Click On The Button To Get An Link In Your Email To Reset Your
          Password
        </Typography>
      </form>
    </Container>
  )
}

export default ForgotPassword
