import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import MODURL from "../MODURL"
import IMAGE_BASE_URL from "../IMAGE_BASE_URL"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  IconButton,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import LogoutIcon from "@mui/icons-material/Logout"
import MenuIcon from "@mui/icons-material/Menu"
import DeleteIcon from "@mui/icons-material/Delete"
import CloseIcon from "@mui/icons-material/Close"

const OrderPage = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const [categories, setCategories] = useState([])
  const [filteredCategory, setFilteredCategory] = useState(null)
  const [saleno, setSaleno] = useState(null)
  const [cart, setCart] = useState([])
  const [cartOpen, setCartOpen] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [qty, setQty] = useState({})
  const [selectedImage, setSelectedImage] = useState(null)
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])
  useEffect(() => {
    const fetchItemsAndCart = async () => {
      const token = localStorage.getItem("token")
      const user = JSON.parse(localStorage.getItem("user"))

      if (!token || !user) {
        navigate("/")
        return
      }

      setSaleno(user.saleno)

      try {
        const itemsResponse = await axios.get(`${MODURL}/items`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        setCategories(itemsResponse.data)

        const cartResponse = await axios.get(`${MODURL}/cart`, {
          headers: { Authorization: `Bearer ${token}` },
        })

        setCart(
          cartResponse.data.map((cartItem) => ({
            id: cartItem.id,
            item_id: cartItem.item_id,
            name: cartItem.item_name,
            sale_no: cartItem.sale_no,
            barcode: cartItem.barcode,
            categoryid: cartItem.categoryid,
            image_link: cartItem.image_link,
            quantity: cartItem.quantity,
          }))
        )
      } catch (error) {
        console.error("Error fetching data:", error)
        if (error.response?.status === 401) {
          navigate("/")
        }
      }
    }

    fetchItemsAndCart()
  }, [navigate])

  const handleImageClick = (image) => {
    setSelectedImage(image)
  }
  const handleLogout = () => {
    localStorage.clear()
    navigate("/")
  }
  const handleClose = () => {
    setSelectedImage(null)
  }
  const handleAddToCart = async (itemId, quantity) => {
    const token = localStorage.getItem("token")

    try {
      await axios.post(
        `${MODURL}/cart/add`,
        { item_id: itemId, quantity },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      const addedItem = categories
        .flatMap((cat) => cat.items)
        .find((i) => i.id === itemId)

      setCart((prevCart) => [
        ...prevCart,
        {
          item_id: itemId,
          quantity,
          name: addedItem.item_name,
          image_link: addedItem.image_link,
        },
      ])
      toast.success(
        <div>
          Item added to cart!{" "}
          <Button
            color="primary"
            size="small"
            onClick={() => setCartOpen(true)}
          >
            View Cart
          </Button>
        </div>
      )
    } catch (error) {
      console.error("Error adding to cart:", error)
      toast.error("Failed to add item to cart.")
    }
  }

  const handleRemoveFromCart = async (item_id) => {
    const token = localStorage.getItem("token")

    try {
      await axios.post(
        `${MODURL}/cart/remove`,
        { item_id },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      setCart((prevCart) => prevCart.filter((item) => item.item_id !== item_id))
      toast.success("Item removed from cart!")
    } catch (error) {
      console.error("Error removing item from cart:", error)
      toast.error("Failed to remove item from cart.")
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        sx={{
          bgcolor: "#1E88E5",
          top: 0,
          zIndex: 1100,
          transition: "all 0.3s ease",
          padding: scrolled ? "1px" : "16px",
        }}
      >
        <Toolbar
          sx={{
            minHeight: scrolled ? 48 : 64, // Reduce height when scrolled
            transition: "all 0.3s ease",
          }}
        >
          <IconButton
            color="inherit"
            onClick={() => setSidebarOpen(true)}
            sx={{
              transform: scrolled ? "scale(0.8)" : "scale(1)",
              transition: "all 0.3s ease",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              marginLeft: { xs: 5, md: 10 },
              fontSize: scrolled ? "0.9rem" : "1.25rem",
              transition: "all 0.3s ease",
            }}
            onClick={() => navigate("/currentorders")}
          >
            View Current Orders
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => setCartOpen(true)}
            sx={{
              transform: scrolled ? "scale(0.8)" : "scale(1)",
              transition: "all 0.3s ease",
            }}
          >
            <Badge badgeContent={cart.length} color="error">
              <ShoppingCartIcon sx={{ fontSize: scrolled ? 20 : 24 }} />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => setOpen(true)}
            sx={{
              transform: scrolled ? "scale(0.8)" : "scale(1)",
              transition: "all 0.3s ease",
            }}
          >
            <LogoutIcon sx={{ fontSize: scrolled ? 20 : 24 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will have to log in again after logging out.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        anchor="left"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        <List>
          <ListItem
            button
            onClick={() => setFilteredCategory(null)}
            sx={{ cursor: "pointer" }} // Add this
          >
            <ListItemText primary="Show All Categories" />
          </ListItem>
          {categories.map((category) => (
            <ListItem
              button
              key={category.category_name}
              onClick={() => {
                setFilteredCategory(category.category_name)
                setSidebarOpen(false)
              }}
              sx={{ cursor: "pointer" }} // Add this
            >
              <ListItemText primary={category.category_name} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box sx={{ textAlign: "center", marginTop: 3 }}>
        {(filteredCategory
          ? categories.filter((cat) => cat.category_name === filteredCategory)
          : categories
        ).map((category) => (
          <Box key={category.category_name} sx={{ mt: 4, mb: 2 }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              {category.category_name}
            </Typography>
            {filteredCategory && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ display: "block", mx: "auto", mt: 2 }}
                onClick={() => setFilteredCategory(null)}
              >
                View All Items
              </Button>
            )}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(3, 1fr)",
                  md: "repeat(4, 1fr)",
                  lg: "repeat(5, 1fr)",
                },
                gap: 1, // Reduce gap to prevent excessive spacing
                padding: 2,
                alignItems: "stretch", // Ensures all cards take equal height
              }}
            >
              {category.items.map((item) => (
                <Card
                  key={item.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // Prevents overlap
                    boxShadow: 3,
                    padding: { xs: 1, sm: 2 },
                    height: "100%", // Ensures all cards are equal height
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`${IMAGE_BASE_URL}${item.image_link}`}
                    alt={item.item_name}
                    sx={{
                      width: "100%",
                      aspectRatio: "1 / 1",
                      objectFit: "cover",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleImageClick(`${IMAGE_BASE_URL}${item.image_link}`)
                    }
                  />
                  <CardContent
                    sx={{ flexGrow: 1, textAlign: "center", pb: "0" }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                      }}
                    >
                      {item.item_name}
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: "0.7rem", sm: "0.9rem" } }}
                    >
                      Design No: {item.barcode}
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: "0.7rem", sm: "0.9rem" } }}
                    >
                      Sale No: {item.sale_no}
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: "0.7rem", sm: "0.9rem" } }}
                    >
                      Rate: {Math.round((item.sale_no * saleno) / 10) * 10}
                    </Typography>
                  </CardContent>
                  <FormControl fullWidth size="small">
                    <InputLabel>Quantity</InputLabel>
                    <Select
                      value={qty[item.id] || 1}
                      onChange={(e) =>
                        setQty((prev) => ({
                          ...prev,
                          [item.id]: e.target.value,
                        }))
                      }
                    >
                      {[1, 2, 3, 6, 9, 12, 18, 24, 36, 50, 75, 100].map((q) => (
                        <MenuItem key={q} value={q}>
                          {q}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    fullWidth
                    sx={{
                      mt: 0.2,
                      fontSize: { xs: "0.7rem", sm: "0.9rem" },
                      padding: { xs: "4px", sm: "6px" },
                    }}
                    variant="contained"
                    color="success"
                    onClick={() => handleAddToCart(item.id, qty[item.id] || 1)}
                  >
                    Add to Cart
                  </Button>
                </Card>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Dialog
        open={!!selectedImage}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
            p: 0,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              backgroundColor: "rgba(255,255,255,0.5)",
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Full Size"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Cart Sidebar (Drawer) */}
      <Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>
        <Box
          sx={{
            width: 300,
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Cart Title */}
          <Typography variant="h6" sx={{ p: 2 }}>
            Your Cart ({cart.length} items)
          </Typography>

          {/* Scrollable List */}
          <Box sx={{ flexGrow: 1, overflowY: "auto", px: 2 }}>
            <List>
              {cart.length === 0 ? (
                <Typography variant="body2" sx={{ textAlign: "center", mt: 2 }}>
                  Your cart is empty.
                </Typography>
              ) : (
                cart.map((cartItem, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      borderBottom: "1px solid #ddd",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={`${IMAGE_BASE_URL}${cartItem.image_link}`}
                      alt={cartItem.name}
                      sx={{
                        width: 50,
                        height: 50,
                        marginRight: 2,
                        borderRadius: 1,
                      }}
                    />
                    <ListItemText
                      primary={cartItem.name}
                      secondary={`Quantity: ${cartItem.quantity}`}
                    />
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveFromCart(cartItem.item_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))
              )}
            </List>
          </Box>

          {/* Sticky Bottom Button */}
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
              p: 2,
              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate("/cart")}
            >
              Click Here To Go To Order Confirmation
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCartOpen(true)}
        sx={{
          position: "fixed",
          bottom: 16,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 9999, // Maximum z-index
          fontSize: "0.7rem", // Slightly smaller
          padding: "6px 12px", // More space to fit text
          minWidth: "auto",
          borderRadius: "20px",
          whiteSpace: "nowrap", // Prevents text wrapping
          display: cartOpen ? "none" : "block", // Hide when cart is open
        }}
      >
        View Cart To Place Final Order
      </Button>
    </Box>
  )
}
export default OrderPage
