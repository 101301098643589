import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import MODURL from "../MODURL"
import IMAGE_BASE_URL from "../IMAGE_BASE_URL"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import MenuIcon from "@mui/icons-material/Menu"

import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  TextField,
  IconButton,
  Badge,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import LogoutIcon from "@mui/icons-material/Logout"
import DeleteIcon from "@mui/icons-material/Delete"

const CartPage = () => {
  const navigate = useNavigate()
  const [cartItems, setCartItems] = useState([])
  const [newQuantity, setNewQuantity] = useState({}) // Store new quantities
  const [open, setOpen] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null) // Track item to delete
  const [cartsidebarOpen, setcartsidebarOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false) // Confirmation dialog for logout
  const [confirmOrderOpen, setConfirmOrderOpen] = useState(false)
  const [processingOrder, setProcessingOrder] = useState(false)

  useEffect(() => {
    const fetchCart = async () => {
      const token = localStorage.getItem("token")

      if (!token) {
        navigate("/")
        return
      }

      try {
        const response = await axios.get(`${MODURL}/cart`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        setCartItems(response.data)
      } catch (error) {
        console.error("Error fetching cart:", error)
      }
    }

    fetchCart()
  }, [navigate])

  // Handle Delete Item
  const handleRemoveFromCart = async (item_id) => {
    const token = localStorage.getItem("token")

    try {
      await axios.post(
        `${MODURL}/cart/remove`,
        { item_id },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      setCartItems((prevCart) =>
        prevCart.filter((item) => item.item_id !== item_id)
      )

      toast.success("Item removed from cart!")
    } catch (error) {
      console.error("Error removing item from cart:", error)
      toast.error("Failed to remove item from cart.")
    } finally {
      setDeleteItem(null) // Close modal
    }
  }

  // Handle Quantity Update
  const handleUpdateQuantity = async (item_id) => {
    const token = localStorage.getItem("token")
    const quantity = newQuantity[item_id] || 1 // Default to 1 if empty

    try {
      await axios.post(
        `${MODURL}/cart/update`,
        { item_id, quantity },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      setCartItems((prevCart) =>
        prevCart.map((item) =>
          item.item_id === item_id ? { ...item, quantity } : item
        )
      )

      toast.success("Quantity updated!")
    } catch (error) {
      console.error("Error updating quantity:", error)
      toast.error("Failed to update quantity.")
    }
  }

  const handleLogout = () => {
    localStorage.clear()
    navigate("/")
  }

  const handleConfirmOrder = async () => {
    const token = localStorage.getItem("token")
    setProcessingOrder(true) // Show processing dialog

    try {
      await axios.post(
        `${MODURL}/confirm-order`,
        { cartItems },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      toast.success("Order confirmed successfully!")
      setCartItems([]) // Clear the cart UI
    } catch (error) {
      console.error("Error confirming order:", error)
      toast.error("Failed to confirm order.")
    } finally {
      setProcessingOrder(false) // Close processing dialog
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Navbar */}
      <AppBar position="static" sx={{ bgcolor: "#1E88E5", padding: 1 }}>
        <Toolbar>
          <IconButton color="inherit" onClick={() => setcartsidebarOpen(true)}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, cursor: "pointer" }}
            onClick={() => navigate("/currentorders")}
          >
            View Current Orders
          </Typography>
          <IconButton color="inherit" onClick={() => navigate("/cart")}>
            <ShoppingCartIcon />
          </IconButton>

          {/* Logout Button */}
          <IconButton color="inherit" onClick={() => setConfirmOpen(true)}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* Sidebar Drawer */}
      <Drawer
        anchor="left"
        open={cartsidebarOpen}
        onClose={() => setcartsidebarOpen(false)}
      >
        <List>
          <ListItem
            button
            onClick={() => navigate("/order")}
            sx={{ cursor: "pointer" }}
          >
            <ListItemText primary="View all Products To Add" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/currentorders")}
            sx={{ cursor: "pointer" }}
          >
            <ListItemText primary="View My Pending Orders" />
          </ListItem>
          <ListItem
            button
            onClick={() => setConfirmOpen(true)}
            sx={{ cursor: "pointer" }}
          >
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {/* Logout Confirmation Modal */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="error">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
      {/* Content */}
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1300, // Ensure it's above all elements
          textAlign: "center",
          width: "100%", // Make sure it spans the full width
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="success"
          size="large"
          onClick={() => setConfirmOrderOpen(true)} // Open confirmation dialog
        >
          Click Here To Confirm Your Order
        </Button>
      </Box>
      <Box sx={{ textAlign: "center", marginTop: 3 }}>
        <Typography variant="h5">Your Cart Items</Typography>
        {cartItems.length === 0 && (
          <Typography variant="h6" color="red" fontWeight="bold">
            Your order might be pending approval for admin, please wait.
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          size="large"
          onClick={() => navigate("/order")}
        >
          Back to Order Page/ Browse More Products To Add
        </Button>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(4, 1fr)",
              lg: "repeat(5, 1fr)",
            },
            alignItems: "stretch", // Ensures all cards take equal height
            gap: 1,
            padding: 0,
          }}
        >
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <Card
                key={item.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between", // Prevents overlap
                  boxShadow: 3,
                  padding: { xs: 1, sm: 2 },
                  height: "100%", // Ensures all cards are equal height
                }}
              >
                <CardMedia
                  component="img"
                  image={`${IMAGE_BASE_URL}${item.image_link}`}
                  alt={item.item_name}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                    }}
                  >
                    {item.item_name}
                  </Typography>

                  {/* Quantity Input */}
                  <Box sx={{ textAlign: "center", mt: 1 }}>
                    <Typography variant="body2" fontWeight="bold">
                      Quantity
                    </Typography>
                    <TextField
                      type="number"
                      size="small"
                      value={newQuantity[item.item_id] || item.quantity}
                      onChange={(e) =>
                        setNewQuantity({
                          ...newQuantity,
                          [item.item_id]: parseInt(e.target.value) || 1,
                        })
                      }
                      sx={{
                        width: 80,
                        textAlign: "center",
                        textAlignLast: "center",
                      }}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      sx={{ mt: 1 }}
                      onClick={() => handleUpdateQuantity(item.item_id)}
                    >
                      Update
                    </Button>
                  </Box>

                  {/* Delete Button */}
                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => setDeleteItem(item.item_id)} // Open delete modal
                  >
                    DELETE
                  </Button>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="h6" color="text.secondary"></Typography>
          )}
        </Box>
        {/* Delete Confirmation Modal */}
        <Dialog open={!!deleteItem} onClose={() => setDeleteItem(null)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove this item from your cart?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteItem(null)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleRemoveFromCart(deleteItem)}
              color="error"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmOrderOpen}
          onClose={() => setConfirmOrderOpen(false)}
        >
          <DialogTitle>Confirm Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to confirm your order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmOrderOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleConfirmOrder()
                setConfirmOrderOpen(false)
              }}
              color="success"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={processingOrder}>
          <DialogTitle>Processing Your Order</DialogTitle>
          <DialogContent>
            <DialogContentText>Please wait...</DialogContentText>
          </DialogContent>
        </Dialog>
        {/* Back to Order Page */}
      </Box>
    </Box>
  )
}

export default CartPage
