import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import MODURL from "../MODURL"
import {
  Container,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Alert,
} from "@mui/material"

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token")
    const user = JSON.parse(localStorage.getItem("user"))

    if (token && user) {
      navigate(user.role === "admin" ? "/order" : "/order")
    }
  }, [navigate])

  const handleLogin = async (e) => {
    e.preventDefault()
    setError("")

    try {
      const response = await axios.post(`${MODURL}/login`, { email, password })

      if (response.data.token) {
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("user", JSON.stringify(response.data.user))

        navigate(response.data.user.role === "admin" ? "/order" : "/order")
      } else {
        setError("Invalid login credentials.")
      }
    } catch (error) {
      if (error.response) {
        setError(
          error.response.data.message || "Login failed. Check your credentials."
        )
      } else {
        setError("An error occurred. Please try again.")
      }
    }
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card sx={{ width: "100%", p: 3, boxShadow: 3, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h7" fontWeight="bold" textAlign="center" mb={2}>
            Welcome To Rishab Gold's Online Ordering Page. Please Create An
            Account If You Not Created One Yet
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            textAlign="center"
            mb={2}
            mt={5}
          >
            Login
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2, textAlign: "center" }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleLogin}>
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#1565c0" },
              }}
            >
              Login
            </Button>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 1, bgcolor: "red" }}
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </Button>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: 1, bgcolor: "green", mb: 4 }}
              onClick={() => navigate("/register")}
            >
              Regsiter An Account
            </Button>
            <Typography variant="h7" textAlign="center" mb={2}>
              Please Note Items That Are In Stock Will Be Dispatched Within 2
              Days , While Those Not In Stock Can Take 1-3 Months Making Time
            </Typography>
          </form>
        </CardContent>
        <Typography variant="h4" fontWeight="bold" textAlign="center" mb={2}>
          Designed By Rushab Oswal
        </Typography>
      </Card>
    </Container>
  )
}

export default Login
