import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"
import AdminDashboard from "./Pages/AdminDashboard"
import Login from "./Pages/Login"
import OrderPage from "./Pages/OrderPage"
import CartPage from "./Pages/CartPage"
import CurrentOrders from "./Pages/CurrentOrders"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Register from "./Pages/Register"
import ForgotPassword from "./Pages/ForgotPassword"
import ResetPassword from "./Pages/ResetPassword"

const ProtectedRoute = ({ element, role }) => {
  const user = JSON.parse(localStorage.getItem("user"))
  const userRole = user ? user.role : null

  if (!userRole) return <Navigate to="/" /> // Redirect to login if not logged in
  if (userRole !== role) return <Navigate to="/" /> // Prevent access to other roles

  return element
}

function App() {
  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute element={<AdminDashboard />} role="admin" />
            }
          />
          <Route
            path="/order"
            element={<ProtectedRoute element={<OrderPage />} role="party" />}
          />
          <Route
            path="/currentorders"
            element={
              <ProtectedRoute element={<CurrentOrders />} role="party" />
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
