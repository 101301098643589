import React, { useState, useEffect } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import axios from "axios"
import {
  TextField,
  Button,
  Container,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material"
import MODURL from "../MODURL"

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const token = searchParams.get("token")
  const email = searchParams.get("email")

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const [openDialog, setOpenDialog] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setMessage("")
    setError("")

    if (password !== confirmPassword) {
      setError("Passwords do not match.")
      return
    }

    try {
      const response = await axios.post(`${MODURL}/reset-password`, {
        email,
        token,
        password,
        password_confirmation: confirmPassword,
      })

      setMessage(response.data.message)
      setOpenDialog(true) // Show dialog on success

      // Redirect to /login after 3 seconds
      setTimeout(() => {
        navigate("/login")
      }, 3000)
    } catch (err) {
      setError("Error resetting password. Invalid token.")
    }
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h5" textAlign="center" mb={2}>
        Reset Password
      </Typography>
      <Typography variant="h7" textAlign="center" mb={2}>
        Please Use A Simple Password, Like Your Mobile Number , Your Full Name
        Or Something Similar to Remember
      </Typography>
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}

      <form onSubmit={handleSubmit}>
        <TextField
          label="New Password"
          type="password"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          margin="normal"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" fullWidth>
          Reset Password
        </Button>
      </form>

      {/* Dialog for Redirecting */}
      <Dialog open={openDialog}>
        <DialogTitle>Password Reset Successful</DialogTitle>
        <DialogContent>Sending you to login in 3 seconds...</DialogContent>
      </Dialog>
    </Container>
  )
}

export default ResetPassword
